.toast-container {
  align-items: center;
  padding: 8px 20px !important;
  .Toastify__close-button {
    align-self: normal;
  }
}

.toast-success {
  background-color: $success !important;
}

.toast-warning {
  background-color: #b85e09 !important;
}

.toast-error {
  background-color: #a21f18 !important;
}
