// BUTTON
div.dropzone {
  cursor: pointer;
  text-align: center;
  vertical-align: center;
}

.gm-style-iw,
.gm-style-iw-c {
  padding: 0px !important;
  border-radius: 0px !important;
}

.result-count {
  font-size: 1rem;
  opacity: 0.5;
  margin-left: 10px;
}

/* Edge exclusive sheets */

@supports (-ms-ime-align: auto) {
  .E-margin {
    margin-right: 5px;
  }
}

/* Internet explorer exclusive sheet*/

@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
  .IE-margin {
    margin-right: 5px;
  }
  .IE-button {
    padding-right: 35%;
  }
  .IE-badge {
    margin-top: 80px;
    right: 0px;
  }
  .IE-sub-bar {
    max-height: 1000px !important;
  }
  .IE-image {
    left: 0px;
  }
}

a {
  color: $secondary;
  text-decoration: none;
  background-color: transparent;
  transition: 0.2s ease all;
  -moz-transition: 0.2s ease all;
  -webkit-transition: 0.2s ease all;
  &:hover {
    text-decoration: none;
    color: $primary;
  }
  &.white {
    color: white;
    text-decoration: underline;
    background-color: transparent;
    transition: 0.2s ease all;
    -moz-transition: 0.2s ease all;
    -webkit-transition: 0.2s ease all;
  }
  &.white:hover {
    color: white !important;
    text-decoration: underline;
  }
}

h4.blue-text {
  color: $primary;
  font-size: 12px;
}

h2.indicator {
  font-size: 26px;
}

.user-link:hover {
  text-decoration: underline;
}

.logo-link:hover {
  color: #fff;
}

.h-100vh {
  height: 100vh;
}

.round {
  border-radius: 50%;
}

.form-group {
  margin-bottom: 1rem;
}

.text-right {
  position: right !important;
}

.mb-login {
  margin-bottom: 10% !important;
}

.flex-d {
  flex-direction: column;
}

.left,
.right {
  width: 50vh;
}

.content-between {
  justify-content: space-between;
}

.vertical-align {
  vertical-align: middle;
}

.title-cooming-soon {
  font-size: 30px;
}

.wrapper {
  padding: 80px 80px 80px 80px;
}

.content-check {
  position: relative !important;
  top: -25px !important;
}

.logo-container {
  color: white;
  position: relative;
  margin-top: 1rem;
}

.logo-link {
  color: #fff;
  font-size: 22px;
  margin: 0 auto;
  width: 100%;
  text-align: center;
  /*position: absolute;*/
  /*bottom: -260px;*/
  /*padding-left: 15vh*/
}

.home-logo {
  width: 100%;
  height: 100%;
  max-width: 420px;
  max-height: 420px;
}

.img-style {
  size: 300px;
}

/* sidebar */
.item-name-user {
  position: relative;
  top: 5px;
}

.item-notification {
  position: relative;
  top: 3px;
}

.divider-nav {
  border-right: 1px solid #ccc;
}

.bg-menu {
  background: #002a3f !important;
  width: 190px;
}

.item-list {
  position: relative !important;
  display: block !important;
  padding: 0.75rem 0rem 0.75rem 2.25rem !important;
  margin-bottom: -1px !important;
  transition: 0.2s ease all;
  -moz-transition: 0.2s ease all;
  -webkit-transition: 0.2s ease all;
}

.home-text {
  margin-top: 25px;
  font-size: 30px;
  font-weight: bolder;
}

.item-content {
  box-shadow: 1px 1px 5px 1px #0000002e;
  padding-left: 30px;
  padding-right: 30px;
  padding-top: 15px;
  padding-bottom: 15px;
  margin-top: 6% !important;
}

.item-textgray {
  font-weight: bold;
  color: #999999;
}

.item-textgray-small {
  font-weight: bold;
  font-size: 11px;
  padding: 0px;
  margin: 1em 0 0 0;
}

.item-textback {
  font-weight: lighter;
}

.item-profile {
  background-image: url('../images/picture.png');
  width: 40px;
  height: 40px;
  background-size: cover;
  background-position: center;
  border-radius: 50%;
}

.item-my-profile {
  /*background-image: url('../image/picture.png');*/
  width: 80px;
  height: 80px;
  background-size: cover;
  background-position: center;
  border-radius: 50%;
}
/*
.content-sub-bar {
  padding-left: 10px;
  padding-right: 10px;
  padding-top: 30px !important;
  margin-bottom: 30px !important;
  z-index: 1;
  -webkit-transform: translateX(0%);
  transform: translateX(0%);
  width: 100.7%;
  text-align: center;
}
*/

.my-school-graph {
  position: absolute;
  padding-left: 150px;
  padding-right: 10px;
  padding-top: 30px !important;
  margin-bottom: 30px !important;
}

.driver-sub-bar-full {
  padding-left: 80px;
  padding-right: 10px;
  padding-top: 10px !important;
  margin-bottom: 30px !important;
}

.driver-sub-bar {
  padding-left: 10px;
  padding-right: 10px;
  padding-top: 40px !important;
  margin-bottom: 30px !important;
  overflow-x: hidden !important;
  overflow-y: auto !important;
}

.driver {
  z-index: 1;
  /*position: absolute;*/
  padding-top: 10px;
  /*top: 0px;*/
  /*bottom: 0px;*/
  transition: -webkit-transform 0.3s ease-out 0s;
  will-change: transform;
  overflow-y: auto !important;
  /*left: 0;*/
  -webkit-transform: translateX(0%);
  transform: translateX(0%);
  width: 100.7%;
}

.dropzone {
  margin-right: 6px;
}

/* ANIMATIONS ================ */

span.normal-text {
  font-size: 0.8rem;
}

.logo-link:hover {
  color: #fff !important;
}

.pointer:hover {
  cursor: pointer !important;
}

.item-list,
.list-group-item-instruction {
  display: block !important;
  padding: 0.75rem 2.25rem !important;
  margin-bottom: -1px !important;
  background-color: #ffffff !important;
  color: #333 !important;
  transition: 0.2s ease all;
  -moz-transition: 0.2s ease all;
  -webkit-transition: 0.2s ease all;
  border: 1px solid rgba(0, 0, 0, 0.125);
}

.pointer {
  cursor: pointer;
}

.chartColumn {
  /* display: flex; */
  flex-direction: column;
  justify-content: space-between;
}

// .colorsBadgeTeacher {
//   color: white;
// }

@media (max-width: 769px) {
  .sub-bar {
    max-width: 100%;
  }
  .sub-bar-coa {
    max-width: 100%;
    margin-left: 0;
  }
  .buttonUpdated {
    display: flex;
    justify-content: center;
  }
}

.menuToggle {
  position: absolute !important;
  right: 0;
  top: 100%;
  width: 100%;
}

.iconToggleMenu {
  cursor: 'pointer';
  margin-top: 10px;
  margin-right: 5px;
}

/*Media query toggle menu*/
@media screen and (max-width: 769px) {
  .iconToggleMenu {
    display: block !important;
  }
}

.table-full-height {
  overflow: auto;
}

@media screen and (min-width: 769px) {
  .iconToggleMenu {
    display: none !important;
  }

  .menuToggle {
    display: none !important;
  }

  .table-full-height {
    height: calc(100vh - 234px);
  }
}

.tab-content {
  width: 100%;
}

.tabs-content {
  /* margin: 0 40px 20px 5px; */
  margin: 0;
}

.content-tab-pane {
  margin-left: 0;
  padding-left: 0;
  height: 640px;
}

.container-marker-image {
  margin-left: 0;
  padding-left: 0;
}

.marker-image {
  width: 28px;
  margin: 15px 10px 10px 0;
}

.gm-style-iw-d {
  overflow: visible !important;
  min-width: 250px;
}

.gm-ui-hover-effect {
  top: 0px !important;
  right: 0px !important;
  opacity: 1 !important;
  filter: invert(1);
}

/* ================ GRAPH RADIAÑ STYLES ================== */
@media (min-width: 970px) {
  .voiceGraph {
    height: 135px !important;
    max-height: 135px !important;
    min-height: 135px !important;
  }
}

@media (max-width: 1024px) {
  .table-button-edit {
    opacity: 1 !important;
  }
  .apexcharts-canvas {
    width: 100% !important;
  }
  // .colorsBadgeTeacher {
  //   color: white;
  //   margin-left: 4.5rem !important;
  // }
}

// @media (min-width: 970px) and (max-width: 1024px) {
//   .contentMySchool {
//     margin-left: 4.5rem !important;
//   }
// }

.modal-header {
  background-color: $primary;
  color: white;
  padding-right: 3rem !important;
}

/* ========================================================= */

.imgLabel {
  padding: 14px;
  cursor: pointer;
  position: relative;
  margin: 0px;
}

.img-promotion {
  width: 100%;
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  border-radius: 10px;
  border-width: 1px;
  border-style: solid;
  border-color: #ccc;
  box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.16), 0 2px 10px 0 rgba(0, 0, 0, 0.12);
}

.school-select-container {
  padding: 10px 10px 10px 32px;
  background-color: white;
}
/* size of container icon left */
.carousel-control-prev {
  width: 50px;
}
/* size of container icon right */
.carousel-control-next {
  width: 50px;
}
/* color for left arrow */
.carousel-control-prev-icon {
  filter: invert(1);
}
/* color for right arrow */
.carousel-control-next-icon {
  filter: invert(1);
}

@media (max-width: 1200px) {
  .pr-5 {
    padding-right: 0px !important;
  }
}

.heightRow {
  height: 90vh !important;
}

.heightRow95 {
  width: 100%;
  height: 100%;
}

.carousel-inner {
  padding: 0 50px;
}

.apexcharts-xaxis-label {
  font-size: 9px !important;
  font-weight: bold;
}

@media (max-width: 414px) {
  .sub-list-item {
    font-size: 15px !important;
  }

  .sub-list-content {
    padding-right: 0px !important;
  }

  .driver-sub-bar {
    padding-right: 0px !important;
  }

  .updateButtonTwo {
    margin-right: 0px !important;
  }

  .img-fluid {
    width: 150px;
  }

  // .text-login-two {
  //   font-size: 20px;
  //   margin-bottom: 1.5rem !important;
  // }
  .group {
    margin-bottom: 20px;
  }

  .forgot-text-login {
    margin-bottom: 2rem !important;
    margin-top: 2rem !important;
    font-size: 15px !important;
  }
}

@media (max-width: 320px) {
  .logo-link {
    font-size: 10px;
  }

  .moreInformation {
    font-size: 10px;
  }

  // .text-login-two {
  //   font-size: 15px;
  // }

  .group {
    margin-bottom: 10px;
  }
}

// .text-login-two {
//   margin-bottom: 3rem;
// }

.driver-sub-bar-col {
  padding-right: 0px;
  overflow-x: hidden;
  overflow-y: auto;
}

.updateButtonTwo {
  margin-right: 3rem;
}

.forgot-text-login {
  margin-bottom: 3rem;
  margin-top: 3rem;
}

// .flexOne {
//   flex: 1;
// }

.pointerEventsClass {
  pointer-events: none;
}

/* HIDE CHECKBOX MAP SCHOOLS */

.gm-style-mtc div + div {
  display: none !important;
}
