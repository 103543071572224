//*
.stepper-container {
  width: 100%;
  margin-bottom: 15px;
  & .stepper-item {
    &:nth-of-type(odd) {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 40px;
      height: 40px;
      border: 2px solid #d9d9d9;
      border-radius: 50%;
      & .stepper-item-outer {
        border-width: 0px;
      }
      & .stepper-item-inner {
        height: 28px;
        width: 28px;
        font-size: 15px;
        &.stepper-item-inner {
          height: 28px;
          width: 28px;
        }
      }
      & .stepper-item-inner-active {
        background-color: $primary;
      }

      & .stepper-item-inner-completed {
        background-color: $primaryLight;
      }
      & .stepper-title {
        font-family: $fontFamily;
        max-width: 150px;
        min-width: 100px;
        transform: translate(0, 35px);
        color: $black;
      }
    }
  }
}
