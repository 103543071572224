input.form-control:focus,
select.form-control:focus,
textarea.form-control:focus {
  box-shadow: none !important;
  border-color: $secondary;
}

// INPUTS VALIDATION
.was-validated .form-control:invalid:focus,
.form-control.is-invalid:focus {
  border-color: $dangerDark;
}

.invalid-feedback {
  color: $dangerDark;
}
