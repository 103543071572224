.btn {
  padding: 0.675rem 0.75rem;
  border-radius: 0;
}

.btn-sm {
  padding: 0.25rem 0.5rem;
}

.b-button {
  background-color: #002a3f;
  transition-duration: 0.4s;
}

.b-button:hover {
  background-color: white;
  color: #002a3f;
  border-color: #002a3f;
}

.btn-outline-primary {
  color: #002a3f;
  border-color: #002a3f;
}

.btn-lightblue {
  background-color: #3c82ff;
  color: white;
  border-color: #3c82ff;
}

.w-button {
  background-color: white;
  transition-duration: 0.4s;
  padding: 10px 10px !important;
}

.w-button:hover {
  background-color: #002a3f;
}

.link-button-outlined {
  color: #002a3f;
  border-color: #002a3f;
}

.link-button-outlined:hover {
  background-color: #002a3f;
  color: #fff !important;
}

.primary-button {
  padding: 7px 10px;
}

.btn-block + .btn-block {
  margin-top: 0 !important;
}

.button-right {
  float: right;
}

button.close {
  color: white !important;
  position: absolute;
  top: 1.4rem;
  right: 2rem;
}

.btn-input-size {
  padding: 0.375rem 0.75rem;
  height: calc(1.5em + 0.75rem + 2px);
}

.dropdown-menu.show {
  max-height: calc(100vh - 200px);
  overflow: auto;
}
