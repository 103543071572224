.table {
  position: relative;
}

.table thead th {
  padding-top: 1.25rem;
  padding-bottom: 1.25rem;
}
.table tbody td {
  padding-top: 1.5rem;
  padding-bottom: 1.5rem;
}

.table-hover tbody tr:hover {
  color: #212529;
  background-color: #f6fbff;
}

.table .table-button-edit {
  cursor: pointer;
  color: rgba(102, 100, 100, 1);
  opacity: 0;
  width: 0;
  height: 0;
}

.table-hover tbody tr:hover .table-button-edit {
  opacity: 1;
  width: 25px;
  height: 25px;
}
