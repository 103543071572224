// .nav {
//   box-shadow: 1px 1px 5px 1px #0000002e;
//   padding: 5px;
//   padding-left: 20px;
//   z-index: 50;
// }

// .nav-tabs {
//   box-shadow: none;
//   padding: 0;
//   padding-left: 0;
//   z-index: 3;
// }

// .nav-tabs .nav-link.active,
// .nav-tabs .nav-item.show .nav-link {
//   color: #002a3f;
//   background-color: #fff;
//   border-color: #002a3f #002a3f #fff;
// }

// .nav-tabs {
//   border-bottom: 1px solid #002a3f;
// }

// .navbar-nav {
//   flex-direction: row !important;
// }

// .nav-tabs {
//   width: 100%;
//   border-bottom: 1px solid #edebeb;
// }

// .nav-tabs .nav-link.active,
// .nav-tabs .nav-item.show .nav-link {
//   border-color: #edebeb #edebeb #fff;
// }

// .nav-tabs .nav-link {
//   border: 1px solid #edebeb;
// }

.sub-bar {
  z-index: 2;
  /*position: sticky;*/
  /*padding-top: 55px;*/
  top: 0px;
  bottom: 0px;
  transition: -webkit-transform 0.3s ease-out 0s;
  will-change: auto;
  overflow-y: auto !important;
  left: 0px;
  transform: translateX(0%);
  /* box-shadow: rgba(0, 0, 0, 0.15) 2px 2px 4px; */
  width: 340px;
  /* min-width: 202px;
  max-width: 400px; */
  background-color: #ffffff;
  border-right: 1px solid #e8e9ed;
  height: 100% !important;
}

.sub-bar-coa {
  z-index: 2;
  /*position: sticky;*/
  /*padding-top: 55px;*/
  top: 0px;
  bottom: 0px;
  transition: -webkit-transform 0.3s ease-out 0s;
  will-change: auto;
  overflow-y: auto !important;
  left: 0px;
  transform: translateX(0%);
  /* box-shadow: rgba(0, 0, 0, 0.15) 2px 2px 4px; */
  /*width: 360px;*/
  width: 340px;
  /* max-width: 400px; */
  background-color: #ffffff;
  border-right: 1px solid #e8e9ed;
  height: 100% !important;
  margin-left: -20px;
}

.sub-list-item {
  position: relative !important;
  display: block !important;
  padding: 0.7rem 2rem !important;
  margin-bottom: -1px !important;
  background-color: #ffffff !important;
  color: $black !important;
  border: none;
  transition: 0.2s ease all;
  -moz-transition: 0.2s ease all;
  -webkit-transition: 0.2s ease all;
  &:nth-child(even) {
    background-color: #f9f9f9 !important;
  }
  &:hover {
    background-color: $infoLight !important;
    border-left: 0;
  }
  &.active {
    background-color: $infoLight !important;
    border-left: 0 !important;
  }
}
